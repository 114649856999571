<template>
  <v-container grid-list-s class="pt-4">
    <v-row>
      <v-col col="3" md="3" class="d-none d-sm-flex">
        <SideNav />
      </v-col>
      <v-divider vertical />
      <v-col col="8" md="8" sm="8">
        <v-row>
          <v-container>
            <h2 class="text-h4 font-weight-bold primary--text ">
              {{ $t('profileTitle') }}
            </h2>
            <v-divider class="mt-4" />
            <h3 class="text-subtitle font-weight-bold my-4">
              {{ $t('personalInformation') }}
            </h3>
            <h6 class="text-subtitle-2 my-4">
              <span class="font-weight-light"
                >{{ $t('referenceNumber') }}:
                {{ userProfile.user.reference_number }}</span
              >
            </h6>
            <PersonalRegistrationForm
              ref="userRegistrationFormRef"
              :form-with-password="false"
              :user="user"
              :countries="countries"
              :hide-file-uploader="true"
            />
            <v-row>
              <br />
              <v-btn
                color="primary"
                large
                type="submit"
                class="text-capitalize col-2 offset-9"
                @click.prevent="updateUserProfile"
              >
                {{ $t('update') }}
              </v-btn>
            </v-row>

            <hr class="my-md-3" />
            <div class="text-h6 left-text-padded text-capitalize">
              {{ $t('passport') }}
            </div>
            <div class="text-subtitle-2 left-text-padded ">
              {{ $t('passportUploaderDetailsSelf') }}
            </div>
            <v-row>
              <v-col md="6">
                <h3 class="text-body-2 secondary--text my-5">
                  {{ $t('currentFileText') }}
                </h3>
                <a
                  v-if="userProfile.user.passport"
                  :href="userProfile.user.passport"
                  :download="userProfile.user.passport"
                  class=" text-decoration-none d-inline-flex"
                >
                  <v-icon x-large color="primary">mdi-file</v-icon>
                </a>
              </v-col>

              <v-col md="6">
                <h3 class="text-body-2 secondary--text my-5">
                  {{ $t('updateFileText') }}
                </h3>
                <v-file-input
                  v-model="passport"
                  :placeholder="$t('passportUploaderPlaceholderSelf')"
                  :label="$t('passport')"
                  prepend-icon="mdi-paperclip"
                  @change="updateFile('profile__passport', passport)"
                />
              </v-col>
            </v-row>

            <hr class="my-md-3" />

            <h3 class="text-subtitle font-weight-bold my-4">
              {{ $t('trustedPerson') }}
            </h3>
            <PersonalRegistrationForm
              ref="trustedPersonRegistrationFormRef"
              :form-with-password="false"
              :user="trustedPerson"
              :countries="countries"
              :hide-file-uploader="true"
            />
            <v-row>
              <br />
              <v-btn
                color="primary"
                large
                type="submit"
                class="text-capitalize col-2 offset-9"
                @click.prevent="updateTrustedPerson"
              >
                {{ userProfile.trusted_person ? $t('update') : $t('submit') }}
              </v-btn>
            </v-row>

            <template v-if="userProfile.trusted_person">
              <hr class="my-md-3" />
              <div class="text-h6 left-text-padded text-capitalize">
                {{ $t('passport') }}
              </div>
              <div class="text-subtitle-2 left-text-padded ">
                {{ $t('passportUploaderDetailsOther') }}
              </div>
              <v-row>
                <v-col md="6">
                  <h3 class="text-body-2 secondary--text my-5">
                    {{ $t('currentFileText') }}
                  </h3>
                  <a
                    v-if="userProfile.trusted_person.passport"
                    :href="userProfile.trusted_person.passport"
                    :download="userProfile.trusted_person.passport"
                    class=" text-decoration-none d-inline-flex"
                  >
                    <v-icon x-large color="primary">mdi-file</v-icon>
                  </a>
                </v-col>

                <v-col md="6">
                  <h3 class="text-body-2 secondary--text my-5">
                    {{ $t('updateFileText') }}
                  </h3>
                  <v-file-input
                    v-model="trustedPersonPassport"
                    :placeholder="$t('passportPlaceholderOther')"
                    :label="$t('passport')"
                    prepend-icon="mdi-paperclip"
                    @change="
                      updateFile(
                        'trusted_person__passport',
                        trustedPersonPassport
                      )
                    "
                  />
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SideNav from '@/components/SideNav.vue'
import PersonalRegistrationForm from '@/components/PersonalRegistrationForm.vue'

export default Vue.extend({
  components: {
    SideNav,
    PersonalRegistrationForm
  },

  data: () => ({
    passport: [],
    trustedPersonPassport: [],
    user: {},
    trustedPerson: {}
  }),
  computed: {
    ...mapGetters(['userProfile', 'countries'])
  },
  async created() {
    await this.getUser()
    this.createUserObject()
    await this.getCountries()
  },
  methods: {
    ...mapActions([
      'getCountries',
      'getUser',
      'patchUser',
      'addNotificationAlert'
    ]),
    formatFields(profile) {
      let formatedProfile = {}

      formatedProfile.email = profile.email
      formatedProfile.lastName = profile.last_name
      formatedProfile.firstName = profile.first_name
      formatedProfile.nationalities =
        profile?.nationalities || profile.nationalities
      formatedProfile.birthPlace = profile?.birth_place || profile.birth_place
      formatedProfile.birthDate = profile?.birth_date || profile.birth_date
      formatedProfile.addressStreet =
        profile?.address?.street || profile.address?.street
      formatedProfile.postalCode =
        profile?.address?.postal_code || profile.address?.postal_code
      formatedProfile.city = profile?.address?.city || profile.address?.city
      formatedProfile.country =
        profile?.address?.country || profile.address?.country
      formatedProfile.phoneCode = profile?.phone_code || profile.phone_code
      formatedProfile.phoneNumber =
        profile?.phone_number || profile.phone_number

      return formatedProfile
    },
    async updateUserProfile() {
      const formValid = this.$refs.userRegistrationFormRef.validate()
      if (!formValid) return

      var formData = new FormData()
      formData.append('email', this.user.email)
      formData.append('first_name', this.user.firstName)
      formData.append('last_name', this.user.lastName)
      formData.append('profile__birth_place', this.user.birthPlace)
      formData.append('profile__phone_code', this.user.phoneCode)
      formData.append('profile__phone_number', this.user.phoneNumber)
      formData.append('profile__nationalities', this.user.nationalities)
      formData.append('profile__address__street', this.user.addressStreet)
      formData.append('profile__address__postal_code', this.user.postalCode)
      formData.append('profile__address__city', this.user.city)
      formData.append('profile__address__country', this.user.country)
      if (this.user.birthDate)
        formData.append('profile__birth_date', this.user.birthDate)
      const res = await this.patchUser(formData)
      if (res.status === 200) {
        this.addNotificationAlert({
          type: 'success',
          text: this.$t('userProfileUpdateSuccess')
        })
      } else {
        this.addNotificationAlert({
          type: 'error',
          text: this.$t('userProfileUpdateFailure')
        })
      }
    },
    async updateTrustedPerson() {
      const formValid = this.$refs.trustedPersonRegistrationFormRef.validate()
      if (!formValid) return

      var formData = new FormData()
      formData.append('trusted_person__email', this.trustedPerson.email)
      formData.append(
        'trusted_person__first_name',
        this.trustedPerson.firstName
      )
      formData.append('trusted_person__last_name', this.trustedPerson.lastName)
      formData.append(
        'trusted_person__birth_place',
        this.trustedPerson.birthPlace
      )
      formData.append(
        'trusted_person__phone_code',
        this.trustedPerson.phoneCode
      )
      formData.append(
        'trusted_person__phone_number',
        this.trustedPerson.phoneNumber
      )
      formData.append(
        'trusted_person__nationalities',
        this.trustedPerson.nationalities
      )
      formData.append(
        'trusted_person__address__street',
        this.trustedPerson.addressStreet
      )
      formData.append(
        'trusted_person__address__postal_code',
        this.trustedPerson.postalCode
      )
      formData.append('trusted_person__address__city', this.trustedPerson.city)
      formData.append(
        'trusted_person__address__country',
        this.trustedPerson.country
      )
      formData.append(
        'trusted_person__birth_date',
        this.trustedPerson.birthDate
      )
      const res = await this.patchUser(formData)
      if (res.status === 200) {
        this.addNotificationAlert({
          type: 'success',
          text: this.$t('trustedPersonProfileUpdateSuccess')
        })
      } else {
        this.addNotificationAlert({
          type: 'error',
          text: this.$t('trustedPersonProfileUpdateFailure')
        })
      }
    },
    async updateFile(fileType, file) {
      if (file) {
        var formData = new FormData()
        formData.append(fileType, file)
        const res = await this.patchUser(formData)
        if (res.status === 200) {
          this.addNotificationAlert({
            type: 'success',
            text: this.$t('fileUploadSuccess')
          })
        } else {
          this.addNotificationAlert({
            type: 'error',
            text: this.$t('fileUploadFailure')
          })
        }
      }
    },
    createUserObject() {
      this.user = this.formatFields(this.userProfile.user)
      if (this.userProfile.trusted_person)
        this.trustedPerson = this.formatFields(this.userProfile.trusted_person)
    }
  }
})
</script>
